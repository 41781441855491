import React, { Component } from 'react'
import { graphql, navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import elasticlunr from 'elasticlunr'
import moment from 'moment'
import he from 'he'
import 'url-search-params-polyfill'

import { Search } from '../components/icons'
import SEO from '../components/seo'
import BlogCard from '../components/cards/blog-card'

class SearchPage extends Component {
	search = null

	constructor(props) {
		super(props)

		const urlParams = new URLSearchParams(this.props.location.search)

		this.state = {
			query: urlParams.get('query') || '',
			results: [],
			length: 12,
		}
	}

	componentDidMount() {
		this._register()

		if (this.state.query) {
			this.setState({
				results: this.search
					.search(this.state.query)
					.map(({ ref }) => this.search.documentStore.getDoc(ref)),
			})
		}

		this.await = true
		window.addEventListener('scroll', this.scrollDetect)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollDetect)
	}

	scrollDetect = () => {
		let currentScroll =
			document.documentElement.scrollTop || document.body.scrollTop
		let { results, length } = this.state

		if (results.length === 0) {
			let { work, blog } = this.props.data
			results = [...work.edges, ...blog.edges]
		}

		if (
			this.await &&
			results.length > length &&
			currentScroll / (document.body.scrollHeight - window.innerHeight) > 0.9
		) {
			this.await = false
			setTimeout(() => (this.await = true), 200)
			this.setState({ length: length + 3 })
		}
	}

	_getResults(query) {
		let ids = []
		let res = []
		this.props.data.work.edges.forEach(el => {
			if (el.node.searchTerms.toLowerCase().includes(query.toLowerCase())) {
				ids.push(el.node.id)
				res.push(el)
			}
		})
		this.props.data.blog.edges.forEach(el => {
			if (el.node.searchTerms.toLowerCase().includes(query.toLowerCase())) {
				ids.push(el.node.id)
				res.push(el)
			}
		})
		this.setState({ results: res })
		return res
	}

	_register() {
		let index = elasticlunr()
		index.addField('title')
		index.addField('content')
		index.setRef('id')

		// TODO: this must be done on the API
		this.props.data.blog.edges.forEach(el => {
			let search_terms = '';
			search_terms += el.node.acf.author_name;
			search_terms += el.node.title;
			//search_terms += el.node.acf.overview.content;
			let blocks = el.node.acf.content_blocks_post;
			for(let i=0; i<blocks.length; i++)
			{
				let block = blocks[i];
				if (block.__typename === 'WordPressAcf_content') {
					search_terms += block.content;
				}
			}
			let categories = el.node.categories;
			let sectors = el.node.sector;
			if(categories) {
				for (let i = 0; i < categories.length; i++) {
					let category = categories[i];
					search_terms += category.name;
				}
			}
			if(sectors) {
				for (let i = 0; i < sectors.length; i++) {
					let sector = sectors[i];
					search_terms += sector.name;
				}
			}
			el.node.searchTerms = search_terms

//			console.log(el.node)
			/*
	 el.node.content = el.node.acf.content_blocks_post.map((block) => {
         if (block.__typename === 'WordPressAcf_content') {
         return block.content.replace(/(<([^>]+)>)/ig, "")
         }
         return ''
	 })
       */
			index.addDoc(el.node)
		})

		// TODO: this must be done on the API
		this.props.data.work.edges.forEach(el => {
			let search_terms = ''
			search_terms += el.node.acf.author_name
			search_terms += el.node.title
			search_terms += el.node.acf.overview.content;

			let categories = el.node.categories;
			let sectors = el.node.sector;
			if(categories)
			{
				for(let i=0; i<categories.length; i++)
				{
					let category = categories[i];
					search_terms += category.name;
				}
			}
			if(sectors) {
				for (let i = 0; i < sectors.length; i++) {
					let sector = sectors[i];
					search_terms += sector.name;
				}
			}


			el.node.searchTerms = search_terms

			/*
	 el.node.content = el.node.acf.overview.map((block) => {
	 return block.content.replace(/(<([^>]+)>)/ig, "")
	 })
       */
			index.addDoc(el.node)
		})

		this.search = index
	}

	_search = event => {
		const query = event.target.value
		this.setState({
			query,
			length: 12,
			// Query the index with search string to get an [] of IDs
			results: this._getResults(query), //this.search.search(query)
			// Map over each ID and return the full document
			//.map(({ ref }) => this.search.documentStore.getDoc(ref)),
		})
	}

	_renderSearch() {
		return (
			<Fade bottom distance="40px">
				<div className="search__input">
					<input
						type="text"
						ref="input"
						placeholder={`Search`}
						onChange={this._search}
						value={this.state.query}
					/>
					<Search colour={'#000'} />
				</div>
			</Fade>
		)
	}

	_renderChoice() {
		const work = () => navigate('/search?type=work')
		const blog = () => navigate('/search?type=blog')

		return (
			<Fade bottom distance="40px">
				<h2>
					{`Looking for `}
					<span onClick={work}>case studies</span>
					{` or `}
					<span onClick={blog}>insights?</span>
				</h2>
			</Fade>
		)
	}

	_renderResult = (el, i) => {
		if (el.node) el = el.node
		let link = '';//`${el.type === 'work' ? '/work' : ''}/${el.slug}`
		if(el.type==='work')
		{
			link = '/work/' + el.slug;
		}
		else
		{
			link = el.slug;
		}

		const props = {
			image:
				el.acf.featured_image &&
				el.acf.featured_image.localFile &&
				el.acf.featured_image.localFile.childImageSharp &&
				el.acf.featured_image.localFile.childImageSharp.fixed.src,
			title: he.decode(el.title),
			link: link,
			author: el.acf.author_name ? el.acf.author_name : 'Project',
			date: moment(el.date).format('MMM, YYYY'),
		}

		return <BlogCard key={i} {...props} />
	}

	render() {
		let { results, length } = this.state

		if (results.length === 0) {
			let { work, blog } = this.props.data
			results = [...work.edges, ...blog.edges]

			setTimeout(() => this.refs.input && this.refs.input.focus(), 500)
		}

		return (
			<>
				<SEO title={'Search'} bodyClass="search" />
				<div
					className={`search__screen ${results.length &&
						'search__screen--results'}`}
				>
					<div className="container">{this._renderSearch()}</div>
				</div>
				<div className="container">
					<div className="search__results">
						{results.length > 0 &&
							results.slice(0, length).map(this._renderResult)}
					</div>
				</div>
			</>
		)
	}
}

export default SearchPage

export const searchQuery = graphql`
	query { 
		work: allWordpressWpWork {
			edges {
				node {
					id
					title
					slug
					type
					categories {
						name
						slug
					}
					sector {
						name
						slug
					}
					acf {
						author_name
						overview {
							content
						}
						featured_image {
							localFile {
								childImageSharp {
									fixed(width: 1200) {
										src
									}
								}
							}
						}
					}
				}
			}
		}
		blog: allWordpressPost {
			edges {
				node {
					id
					title
					slug
					date
					type
					categories {
						name
						slug
					}
					sector {
						name
						slug
					}
					acf {
						author_name
						content_blocks_post {
							__typename
							... on WordPressAcf_content {
								content
							}
							... on WordPressAcf_image {
								image {
									alt_text
									localFile {
										childImageSharp {
											original {
												src
											}
										}
									}
								}
							}
							... on WordPressAcf_blockquote {
								blockquote
							}
							... on WordPressAcf_video {
								video
								poster {
									alt_text
									localFile {
										childImageSharp {
											original {
												src
											}
										}
									}
								}
								title
							}
							... on WordPressAcf_video_id {
								youtube_video_id
								video_format
								poster_image {
									alt_text
									localFile {
										childImageSharp {
											original {
												src
											}
										}
									}
								}
							}
						}
						featured_image {
							localFile {
								childImageSharp {
									fixed(width: 1200) {
										src
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
